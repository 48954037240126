<template>
  <div
    v-if="!submitSuccessful"
    id="main-wrapper"
  >
    <div class="row">
      <div class="col-xs-12">
        <div class="panel">
          <div class="panel-body">
            <div class="panel-heading form">
              <h2>Upload Documents</h2>
            </div>
            <div
              class="row"
              style="padding-top: 15px"
            >
              <div class="col-xs-offset-2 col-xs-8">
                <div
                  id="photoIdProof"
                  class="dropzone"
                >
                  <div
                    id="dropzone-message"
                    style="display: none"
                  >
                    <span class="dropzone-title">Drop files here or click to select</span>
                    <span class="dropzone-info">You can upload multiple files at once</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 non-float-static">
                <label class="form-details-label">The following documents are required:</label>
                <p class="text-muted">
                  <i class="fas fa-check text-info" />
                  Photo ID Proof
                </p>
                <p class="text-muted">
                  <i class="fas fa-check text-info" />
                  Address Proof
                </p>
                <p class="text-muted">
                  <i class="fas fa-check text-info" />
                  Passport Photo
                </p>
                <p
                  v-if="formDetails.offerType === 'CARD TO CARD'"
                  class="text-muted"
                >
                  <i class="fas fa-check text-info" />
                  Latest Credit Card Statement
                  <span class="text-danger">(ensure document is not password protected or provide password below)</span>
                </p>
                <p
                  v-if="formDetails.offerType === 'CARD TO CARD'"
                  class="text-muted"
                >
                  <i class="fas fa-check text-info" />
                  Front Copy Of Existing Credit Card
                  <span class="text-danger">(or provide the credit card number below)</span>
                </p>
                <p
                  v-if="formDetails.offerType === 'SALARIED'"
                  class="text-muted"
                >
                  <i class="fas fa-check text-info" />
                  Last Two Month Salary Slip
                  <span class="text-danger">(ensure document is not password protected or provide password below)</span>
                </p>
                <p
                  v-if="formDetails.offerType === 'SALARIED'"
                  class="text-muted"
                >
                  <i class="fas fa-check text-info" />
                  Last Three Month Bank Statements
                  <span
                    class="text-danger"
                  >(ensure documents are not password protected or provide password below)</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div class="panel">
          <div class="panel-body">
            <div class="panel-heading form">
              <h2>Additional Required Details</h2>
            </div>
            <div class="row cardToCard">
              <div
                v-if="formDetails.offerType === 'CARD TO CARD'"
                class="col-sm-offset-1 col-sm-5"
              >
                <label class="has-float-label">
                  <input
                    v-model="updateDetails.extCCStatementPassword"
                    placeholder=" "
                    type="password"
                  >
                  <span class="float-label">Password for credit card statement document</span>
                </label>
              </div>
              <div
                v-if="formDetails.offerType === 'CARD TO CARD'"
                class="col-sm-5"
              >
                <label class="has-float-label">
                  <input
                    v-model="updateDetails.extCCNumber"
                    placeholder=" "
                    type="password"
                  >
                  <span class="float-label">Existing Credit Card Number</span>
                </label>
              </div>
            </div>
            <div class="row salaried">
              <div
                v-if="formDetails.offerType === 'SALARIED'"
                class="col-sm-3"
              >
                <label class="has-float-label">
                  <input
                    v-model="updateDetails.salarySlipPassword"
                    placeholder=" "
                    type="password"
                  >
                  <span class="float-label">Password for salary slip document</span>
                </label>
              </div>
              <div
                v-if="formDetails.offerType === 'SALARIED'"
                class="col-sm-3"
              >
                <label class="has-float-label">
                  <input
                    v-model="updateDetails.bankStatementPassword1"
                    placeholder=" "
                    type="password"
                  >
                  <span class="float-label">Password for bank statement document 1</span>
                </label>
              </div>
              <div
                v-if="formDetails.offerType === 'SALARIED'"
                class="col-sm-3"
              >
                <label class="has-float-label">
                  <input
                    v-model="updateDetails.bankStatementPassword2"
                    placeholder=" "
                    type="password"
                  >
                  <span class="float-label">Password for bank statement document 2</span>
                </label>
              </div>
              <div
                v-if="formDetails.offerType === 'SALARIED'"
                class="col-sm-3"
              >
                <label class="has-float-label">
                  <input
                    v-model="updateDetails.bankStatementPassword3"
                    placeholder=" "
                    type="password"
                  >
                  <span class="float-label">Password for bank statement document 3</span>
                </label>
              </div>
            </div>
            <form
              id="additionalInfo"
              class="row"
            >
              <div class="col-sm-offset-1 col-sm-5">
                <label class="has-float-label">
                  <input
                    v-model="updateDetails.motherName"
                    placeholder=" "
                    type="text"
                    @keypress.stop.prevent="formatInput"
                    @change.stop.prevent="formatInputMobile"
                  >
                  <span class="float-label">Mother's Name</span>
                </label>
              </div>
              <div class="col-sm-5">
                <label class="has-float-label">
                  <input
                    v-model="updateDetails.yearsEmployed"
                    placeholder=" "
                    type="text"
                    @keypress.stop.prevent="formatInput"
                    @change.stop.prevent="formatInputMobile"
                  >
                  <span class="float-label">Number Of Years Employed</span>
                </label>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <div
          class="panel"
          style="margin-bottom: 0"
        >
          <div class="panel-body">
            <div class="panel-heading form">
              <h2>Verify Personal Details</h2>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-offset-1 col-xs-11">
                    <h3>Applicant</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-1">
                    <label class="form-details-label">Title:</label>
                    <p class="text-muted">
                      {{ formDetails.title }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">First Name:</label>
                    <p class="text-muted">
                      {{ formDetails.firstName }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">Father Name:</label>
                    <p class="text-muted">
                      {{ formDetails.fatherName }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">Last Name:</label>
                    <p class="text-muted">
                      {{ formDetails.lastName }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">Date Of Birth:</label>
                    <p class="text-muted">
                      {{ prettyPrint(formDetails.dateOfBirth, 'Date') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">Gender:</label>
                    <p class="text-muted">
                      {{ formDetails.gender }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-11 col-sm-offset-1 col-sm-1">
                    <label class="form-details-label">Pancard:</label>
                    <p class="text-muted">
                      {{ formDetails.pancard }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-2 col-sm-3">
                    <label class="form-details-label">Personal Email ID:</label>
                    <p class="text-muted">
                      {{ formDetails.email }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label class="form-details-label">Mobile Number:</label>
                    <p class="text-muted">
                      {{ formDetails.mobileNumber }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">Alternate Number:</label>
                    <p class="text-muted">
                      {{ formDetails.mobileNumberAlternate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-offset-1 col-xs-11">
                    <h3>Resident Address</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-5">
                    <label class="form-details-label">Address Line 1:</label>
                    <p class="text-muted">
                      {{ formDetails.addressLine1 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-5">
                    <label class="form-details-label">Address Line 2:</label>
                    <p class="text-muted">
                      {{ formDetails.addressLine2 }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label class="form-details-label">Landmark:</label>
                    <p class="text-muted">
                      {{ formDetails.landmark }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label class="form-details-label">Station:</label>
                    <p class="text-muted">
                      {{ formDetails.station }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label class="form-details-label">City:</label>
                    <p class="text-muted">
                      {{ formDetails.city }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">Pincode:</label>
                    <p class="text-muted">
                      {{ formDetails.zipcode }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-offset-1 col-xs-11">
                    <h3>Employment</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label class="form-details-label">Employment Status:</label>
                    <p class="text-muted">
                      {{ formDetails.employmentStatus }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label class="form-details-label">Net Monthly Salary / Income:</label>
                    <p class="text-muted">
                      {{ prettyPrint(formDetails.netMonthlyIncome, 'Currency') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label class="form-details-label">Company Name:</label>
                    <p class="text-muted">
                      {{ formDetails.company }}
                    </p>
                  </div>
                  <div
                    v-if="formDetails.companyListed"
                    class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2"
                  >
                    <label class="form-details-label">Company Listed ?</label>
                    <p class="text-muted">
                      {{ formDetails.companyListed }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xs-offset-1 col-xs-11">
                    <h5>Company Address</h5>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-5">
                    <label class="form-details-label">Address Line 1:</label>
                    <p class="text-muted">
                      {{ formDetails.companyAddressLine1 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-5">
                    <label class="form-details-label">Address Line 2:</label>
                    <p class="text-muted">
                      {{ formDetails.companyAddressLine2 }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-2">
                    <label class="form-details-label">Landmark:</label>
                    <p class="text-muted">
                      {{ formDetails.companyLandmark }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label class="form-details-label">Station:</label>
                    <p class="text-muted">
                      {{ formDetails.companyStation }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label class="form-details-label">City:</label>
                    <p class="text-muted">
                      {{ formDetails.companyCity }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">Pincode:</label>
                    <p class="text-muted">
                      {{ formDetails.companyZipcode }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="formDetails.extCCBank1 && formDetails.extCCBank1 !== ''"
              class="row"
            >
              <div class="col-xs-12">
                <div class="row">
                  <div class="col-xs-offset-1 col-xs-11">
                    <h3>Exisiting Accounts</h3>
                  </div>
                </div>
                <div
                  v-if="formDetails.extCCBank1 && formDetails.extCCBank1 !== ''"
                  class="row"
                >
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label class="form-details-label">1 Existing CC. Bank:</label>
                    <p class="text-muted">
                      {{ formDetails.extCCBank1 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label class="form-details-label">Using From (In Months):</label>
                    <p class="text-muted">
                      {{ formDetails.extCCUsingFrom1 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">Total Limit:</label>
                    <p class="text-muted">
                      {{ prettyPrint(formDetails.extCCTotalLimit1, 'Currency') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label class="form-details-label">Available Limit:</label>
                    <p class="text-muted">
                      {{ prettyPrint(formDetails.extCCAvailableLimit1, 'Currency') }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="formDetails.extCCBank2 && formDetails.extCCBank2 !== ''"
                  class="row"
                >
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-1 col-sm-3">
                    <label class="form-details-label">2 Existing CC. Bank:</label>
                    <p class="text-muted">
                      {{ formDetails.extCCBank2 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label class="form-details-label">Using From (In Months):</label>
                    <p class="text-muted">
                      {{ formDetails.extCCUsingFrom2 }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-2">
                    <label class="form-details-label">Total Limit:</label>
                    <p class="text-muted">
                      {{ prettyPrint(formDetails.extCCTotalLimit2, 'Currency') }}
                    </p>
                  </div>
                  <div class="form-group col-xs-offset-1 col-xs-5 col-sm-offset-0 col-sm-3">
                    <label class="form-details-label">Available Limit:</label>
                    <p class="text-muted">
                      {{ prettyPrint(formDetails.extCCAvailableLimit2, 'Currency') }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row m-t-lg">
      <div class="col-xs-12 pull-right">
        <input
          class="btn btn-default form-submit"
          type="button"
          value="Submit Form"
          @click.stop.prevent="verifySubmission"
        >
      </div>
    </div>
    <div
      id="confirmUploadModal"
      aria-hidden="true"
      aria-labelledby="confirmUploadModalLabel"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button
              aria-label="Close"
              class="close"
              data-dismiss="modal"
              type="button"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h2
              id="confirmUploadModalLabel"
              class="modal-title text-center"
            >
              Are you Sure?
            </h2>
          </div>
          <div class="modal-body">
            In order to speed up the process and confirm your application as soon as possible, please ensure that all of
            the required documents have been uploaded.<br><br>
            By clicking the confirm button below you verify that all of the information included in this application is
            accurate and up to date.
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-default pull-left"
              data-dismiss="modal"
              type="button"
            >
              Cancel
            </button>
            <button
              class="btn btn-success"
              type="button"
              @click.stop.prevent="submitDocuments"
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  </div><!-- Main Wrapper -->
  <div
    v-else
    class="text-center"
  >
    <h2>{{ title }}</h2>
    <h3>{{ message }}</h3>
  </div>
</template>

<script>
import Dropzone from "dropzone";
import {API, Storage} from "aws-amplify";

export default {
  name: "UploadDocuments",
  data() {
    return {
      apiName: "SalesVoltPublic",
      updateDetails: {
        motherName: '',
        yearsEmployed: '',
        extCCStatementPassword: '',
        extCCNumber: '',
        salarySlipPassword: '',
        bankStatementPassword1: '',
        bankStatementPassword2: '',
        bankStatementPassword3: ''
      },
      formDetails: {},
      documentUploader: null,
      submitSuccessful: false,
      title: 'Thank You!',
      message: 'Your documents were submitted successfully!',
      formId: ''
    }
  },
  created() {
    Dropzone.autoDiscover = false;
    this.formId = _.split(window.location.href, '?')[1];
    if (!this.formId || this.formId === '') {
      this.title = 'Sorry!';
      this.message = "Please verify that you have the correct link.";
      this.submitSuccessful = true;
    }
  },
  mounted() {
    if (this.formId && this.formId !== '') {
      let options = {
        url: '/',
        method: 'put',
        acceptedFiles: "image/jpeg,image/png,application/pdf",
        addRemoveLinks: true,
        parallelUploads: 1,
        uploadMultiple: false,
        header: '',
        dictDefaultMessage: document.querySelector('#dropzone-message').innerHTML,
        autoProcessQueue: false
      };

      // Instantiate DropZone
      this.documentUploader = new Dropzone("div#photoIdProof", options);

      this.fetchForm();
    }
  },
  methods: {
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    prettyPrint: function (value, type) {
      if (type === 'Date') {
        if (_.includes(value, 'T')) {
          return moment(value).format('dddd, MMMM Do YYYY h:mm A');
        } else if (!value || value === '') {
          return '';
        } else {
          return moment(value, "YYYY-MM-DD").format('DD-MM-YYYY');
        }
      } else if (type === 'Currency' && value && value !== '') {
        value = value.toString().replace(/,/g, '');
        let lastThree = value.substring(value.length - 3);
        let otherNumbers = value.substring(0, value.length - 3);
        if (otherNumbers !== '') {
          lastThree = ',' + lastThree;
        }
        return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      }
    },
    formatInput: function (e) {
      if (e.target.name === 'updateDetails.yearsEmployed') {
        if (!(e.keyCode < 48 || e.keyCode > 57)) {
          let start = e.target.selectionStart;
          let end = e.target.selectionEnd;
          _.set(this, e.target.name, e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end));
          this.$nextTick(() => {
            e.target.setSelectionRange(start + 1, start + 1);
          });
        }
      } else if (e.target.name === 'updateDetails.motherName') {
        let newCharUpper = _.toUpper(String.fromCharCode(e.keyCode));
        let start = e.target.selectionStart;
        let end = e.target.selectionEnd;
        _.set(this, e.target.name, e.target.value.substring(0, start) + newCharUpper + e.target.value.substring(end));
        this.$nextTick(() => {
          e.target.setSelectionRange(start + 1, start + 1);
        });
      }
    },
    formatInputMobile: function (e) {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        if (e.target.name === 'updateDetails.yearsEmployed') {
          // if (!(e.keyCode < 48 || e.keyCode > 57)) {
          // 	let start = e.target.selectionStart;
          // 	let end = e.target.selectionEnd;
          // 	_.set(this, e.target.name, e.target.value.substring(0, start) + String.fromCharCode(e.keyCode) + e.target.value.substring(end));
          // this.$nextTick(() => {
          // 	e.target.setSelectionRange(start + 1, start + 1);
          // });
          // }
        } else if (e.target.name === 'updateDetails.motherName') {
          _.set(this, e.target.name, _.toUpper(e.target.value));
        }
      }
    },
    fetchForm: function () {
      API.get(this.apiName, "/form", {queryStringParameters: {formId: this.formId}}).then(response => {
        if (response) {
          this.formDetails = response;
          this.updateDetails.motherName = this.formDetails.motherName || '';
          this.updateDetails.yearsEmployed = this.formDetails.yearsEmployed || '';
        }
      }).catch(() => {
        this.title = "Sorry!";
        this.message = "Please verify that you have the correct link.";
        this.submitSuccessful = true;
        this.setMessage('v-notify-error', "Sorry!", "Your link is either invalid or expired!", 5000);
      });
    },
    verifySubmission: function () {
      this.$validator.validateAll()
          .then((result) => {
            if (result) {
              $('#confirmUploadModal').modal('show');
            } else {
              let firstErrorInput = $('input.error:first')[0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
              if (this.errors.items && this.errors.items.length > 1) {
                this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
              } else {
                let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
                if (errorInputName && errorInputName !== '') {
                  errorInputName = _.upperCase(_.replace(errorInputName, "updateDetails.", ""));
                  this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
                } else {
                  this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
                }
              }
            }
          })
          .catch(() => {
            let firstErrorInput = $('input.error:first')[0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
            if (this.errors.items && this.errors.items.length > 1) {
              this.setMessage('v-notify-error', "Oops!", "Please correct the " + this.errors.items.length + " errors on the form.");
            } else {
              let errorInputName = firstErrorInput.dataset.vvAs || firstErrorInput.name || 'form';
              if (errorInputName && errorInputName !== '') {
                errorInputName = _.upperCase(_.replace(errorInputName, "updateDetails.", ""));
                this.setMessage('v-notify-error', "So Close!", "There is an issue with the " + errorInputName + " field.");
              } else {
                this.setMessage('v-notify-error', "Sorry!", "Errors in the form!");
              }
            }
          });
    },
    async submitDocuments() {
      const body = $('body');
      try {
        $('#confirmUploadModal').modal('hide');
        body.removeClass('loaded');
        this.updateDetails.submittedDocuments = this.formDetails.submittedDocuments || [];
        let files = this.documentUploader.getQueuedFiles();
        if (files && files.length > 0) {
          let documents = [];
          _.forEach(files, file => {
            documents.push(Storage.put(this.formDetails.id + '/' + file.name, file, {
              level: 'public',
              contentType: file.type
            }));
          });
          Promise.all(documents)
              .then(results => {
                _.forEach(results, (result) => {
                  let documentKey = _.split(result.key, '/')[1];
                  this.updateDetails.submittedDocuments.push(documentKey);
                });

                this.updateDetails.id = this.formDetails.id;

                API.post(this.apiName, "/documents", {
                  body: _.omitBy(this.updateDetails, (field) => {
                    return (!field) || field === '';
                  })
                }).then(() => {
                  this.documentUploader.removeAllFiles();
                  this.submitSuccessful = true;
                  body.addClass('loaded');
                }).catch(() => {
                  this.setMessage('v-notify-error', "Sorry!", "There was an error uploading your documents. PLease try again later.");
                  $('body').addClass('loaded');
                });
              }).catch(() => {
            this.setMessage('v-notify-error', "Sorry!", "There was an error uploading your documents. PLease try again later.");
            body.addClass('loaded');
          });
        } else {
          this.setMessage('v-notify-error', "Error!", "No documents were submitted!");
          body.addClass('loaded');
        }
      } catch (e) {
        this.setMessage('v-notify-error', "Sorry!", "There was an error uploading your documents. PLease try again later.");
        body.addClass('loaded');
      }
    }
  }
}
</script>

<style scoped>

</style>
